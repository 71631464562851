import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class QuizService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/quiz`;
    }

    paginate() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }


    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    getQuestion(id) {
        let url = `${this.#api}/${id}/questions`
        return apiService.get(url);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    sort(data) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }


    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    calculateResult(id) {
        let url = `${this.#api}/${id}/calculate-result`
        return apiService.get(url);
    }

    deleteSelectedQuizQuestion(id, questionId) {
        let url = `${this.#api}/${id}/question/${questionId}/delete`
        return apiService.delete(url);
    }

    quizResult(id) {
        let url = `${this.#api}/${id}/quiz-result`
        return apiService.get(url);
    }


}
