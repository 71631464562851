import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CoursesService from "@/core/services/courses/CoursesService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import TopicService from "@/core/services/lesson-planner/topic/TopicService";
import ActivityService from "@/core/services/lesson-planner/activity/ActivityService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LessonsService from "@/core/services/lesson-planner/lessons/LessonsService";
import ActivityContentService from "@/core/services/lesson-planner/activity-content/ActivityContentService";
import QuestionBankService from "@/core/services/quiz/question-bank/QuestionBankService";
import QuestionBankCategoryService from "@/core/services/quiz/question-bank/category/QuestionBankCategoryService";
import QuizService from "@/core/services/quiz/QuizService";

const quizService = new QuizService();
const lessonService = new LessonsService();
const activityService = new ActivityService();
const topicService = new TopicService();
const gradeLevelService = new GradeLevelService();
const courseService = new CoursesService();
const programService = new ProgramService();
const lessonPlannerService = new LessonPlannerService();
const academicYearService = new AcademicYearService();
const academicClassService = new AcademicClassService();
const activityContentService = new ActivityContentService();
const questionBankService = new QuestionBankService();
const questionBankCategory = new QuestionBankCategoryService();


export const QuizMixin = {
    data() {
        return {
            search: {
                title: '',
                is_active: ''
            },
            options: [{
                title: null,
                is_correct: false
            }],
            category_questions: [],
            answerList: [],
            categories: [],
            questions: [],
            programs: [],
            courses: [],
            academic_years: [],
            gradesLevels: [],
            question_bank_types: [
                {title: 'Text', value: 'text'},
                {title: 'File', value: 'file'},
            ],
            types: [
                {title: 'Text', value: 'text'},
                {title: 'File', value: 'file'},
            ],


            quiz: {
                academic_year_id: null,
                type: null,
                title: null,
                program_id: null,
                course_id: null,
                schedule_at: null,
                start_time: null,
                end_time: null,
                question_order: null,
                answer_order: null,
                time_length: null,
                feedback_enable: 0,
                display_result: 0,
                display_per_page: 1,
                no_of_attempts_allowed: 1,
                is_pausable: 0,
                total_marks:null,
                is_active: true,
                duration_type:'minute'
            },
            question: {
                "question": "",
                "program_ids": "",
                "options": null,
                "answer": null,
                "type": "text",
                "question_category_id": null,
            },
            isBusy: false,
            page: null,
            total: null,
            perPage: null,
            quiz_result: []
        }
    },
    mounted() {
        this.getAcademicYears();
        this.getPrograms();
    },
    methods: {
        questionBankCategories() {
            questionBankCategory.allActive().then(response => {
                this.categories = response.data
            })
        },
        getPrograms() {
            programService.all().then(response => {
                this.programs = response.data;
            });
        },
        getAcademicYears() {
            academicYearService.all().then(response => {
                this.academic_years = response.data;
            });
        },

        getGrades() {
            gradeLevelService.getByProgram(this.lesson_plan.program_id).then(response => {
                this.gradesLevels = response.data;
            });
        },
        getGradesByProgramId(program_id) {
            gradeLevelService.getByProgram(program_id).then(response => {
                this.gradesLevels = response.data;
            });
        },
        getAllGrades() {
            gradeLevelService.all().then(response => {
                this.gradesLevels = response.data.data;
            });
        },
        getAllCourses() {
            courseService.all().then(response => {
                this.courses = response.data.data;
            });
        },
        getQuizQuestions(id) {
            quizService.getQuestion(id).then(response => {
                this.quiz_questions.length = 0;
                if(response.data.questions.length > 0){
                    this.quiz_questions = response.data.questions;
                }else{
                    this.quiz_questions =[];
                }
            })
        },
        getQuestionsByProgram(program_id, question_bank_category_id) {
            let data = {
                program_id: program_id,
                category_id: question_bank_category_id
            }
            questionBankService.getByProgram(data).then(response => {
                this.questions = response.data.questions;
            })
        },

        // getCourses() {
        //     courseService.getByProgram(this.question_bank.program_id).then(response => {
        //         this.courses = response.data.courses;
        //     });
        // },
        getCourses() {
            let search = {
                academic_year_id: this.question_bank.academic_year_id,
                program_id: this.question_bank.program_id
            }
            this.$bus.emit('toggleLoader',);
            courseService.paginate(search).then(response => {
                this.courses = response.data.data;
                this.$bus.emit('toggleLoader');
            });
        },
        addOption() {
            this.options.push({
                title: null,
                is_correct: 0
            })
        },
        removeOption(index) {
            this.options.splice(index, 1);

        },
        addToCorrectAnswer(index) {
            this.question.answer = null;
            if (this.options[index].is_correct) {

                this.options.forEach(option=>{
                    option.is_correct = false;
                });
                this.options[index].is_correct =true;
                if (this.answerList.indexOf(this.options[index].title))
                    this.answerList.push(this.options[index].title)

            } else {

                this.answerList.splice(this.answerList.indexOf(this.options[index].title), 1)
            }
        },

        getQuestions(data, page) {
            questionBankService.paginate(this.search, this.page).then(response => {
                this.question_banks = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            })
        },

        getQuestion(id) {
            questionBankService.show(id).then(response => {
                this.question = response.data.quiz;
                this.buildForEdit()
            })
        },
        createQuestion(data) {
            questionBankService.store(data).then(response => {
                this.$snotify.success("Information added");
                this.resetForm();
                this.redirectTo();
            }).catch(error => {

                this.$snotify.error("Unable to process request")
            }).finally(()=>{
                this.isBusy = false


            })
        },
        updateQuestion(id, data) {
            questionBankService.update(id, data).then(response => {
                this.$snotify.success("Information updated");
                this.resetForm();
                this.redirectTo()
            }).catch(error => {
                this.isBusy = false
                this.$snotify.error("Unable to process request")
            }).finally(()=>{
                this.isBusy = false;
                this.resetForm();
            })
        },

        deleteQuestionBank(id) {
            questionBankService.delete(id).then(response => {
                this.getQuestions();
                this.$snotify.success("Information deleted")
            })
        },
        deleteQuiz(id) {
            quizService.delete(id).then(response => {
                this.__getQuiz();
                this.$snotify.success("Information deleted")
            })
        },
        __updateQuiz(fd) {
            this.isBusy = true;
            quizService
                .update(this.quiz.id, fd)
                .then(response => {
                    this.isBusy = false;
                    this.$snotify.success("Information updated");
                    this.__getQuiz();

                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                    // console.log(error);
                });
        },

        __getQuiz() {
            quizService
                .paginate().then(response => {
                this.quizes = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            })
        },
        __createQuiz(fd) {
            this.isBusy = true;
            quizService
                .store(fd)
                .then((response) => {
                    this.isBusy = false;
                    // this.resetForm();
                    if (response.data.quiz) {
                        this.$router.push({name: 'quiz-update', params: {quiz_id: response.data.quiz.id}})
                    }
                    this.$snotify.success("Information added");

                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                    // console.log(error);
                });
        },
        resetForm() {
            this.options=[
                {
                    title: null,
                    is_correct: false
                }
            ];
            this.question = {
                question: "",
                program_ids: "",
                options: [],
                answer: null,
                type: "text",
            };
            this.$v.$reset();

        },


        questionsByCategory(programId) {
            questionBankService.questionsByCategoryAndProgram(this.category_id, programId).then(response => {
                this.category_questions = response.data.questions
                this.addAllCategoryQuestionToList()
            }).catch(error => {
                this.$snotify.error("Something went wrong");
                this.isBusy = false;
            })
        },

        deleteSelectedQuizQuestion(quizId, questionId) {
            quizService.deleteSelectedQuizQuestion(quizId, questionId).then(response => {
                this.$snotify.success("Information deleted")
                this.getQuizQuestions(quizId)
            })
        },

        calculateResult(id) {
            quizService.calculateResult(id).then(response => {
                this.$snotify.success('Request has been processed')
            }).catch(error => {
                this.$snotify.error('Something went wrong')
            })
        },

        getQuizResult(id) {
            quizService.quizResult(id).then(response => {
                this.quiz_result = response.data.result
            })
        }
    },
}
